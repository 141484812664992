<template>
<span>
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 850.39 850.39" xml:space="preserve" :width="width" :height="height" :style="{top: topPx, left: leftPx}">
  <path :fill="fill1" :stroke="stroke1" stroke-width="7px" d="M585.36,447.72c2.06-9.61,3.54-19.49,4.37-29.62c8.58-105.14-55.86-196.18-143.91-203.36  c-88.05-7.18-166.38,72.22-174.95,177.35c-1.25,15.36-0.93,30.39,0.77,44.92c-35.27,36.6-58.8,85.94-63.34,141.6  c-10.1,123.89,77.67,232.16,196.03,241.81c118.37,9.65,222.5-82.96,232.61-206.87C642.06,550.62,621.92,491.71,585.36,447.72z"/>
  <path :fill="fill2" d="M494.49,534.95c-2.38,29.08-36.92,49.98-77.16,46.69c-40.24-3.28-70.95-29.5-68.57-58.58  c2.37-29.07,36.91-49.97,77.15-46.68C466.16,479.66,496.86,505.89,494.49,534.95z" :stroke="stroke1" stroke-width="5px"/>
  <path fill="#FAF7F7" d="M400.04,381.83c1.9-23.34-10.24-43.38-27.12-44.76c-16.88-1.37-32.12,16.43-34.02,39.77  c-1.9,23.34,10.25,43.38,27.13,44.76C382.9,422.98,398.13,405.17,400.04,381.83z" :stroke="stroke1" stroke-width="5px"/>
  <path fill="#F8F7F7" d="M524.99,383.51c1.9-23.34-10.25-43.38-27.13-44.76c-16.89-1.38-32.12,16.43-34.02,39.76  c-1.9,23.35,10.25,43.4,27.13,44.76C507.85,424.65,523.09,406.85,524.99,383.51z" :stroke="stroke1" stroke-width="5px"/>
  <path fill="#040506" d="M387.35,393.01c-1.35,16.64-12.12,29.34-24.04,28.37c-11.92-0.96-20.48-15.25-19.13-31.88  c1.36-16.64,12.12-29.34,24.04-28.36C380.15,362.1,388.71,376.38,387.35,393.01z"/>
  <path fill="#040506" d="M515,394.91c-1.35,16.64-12.11,29.33-24.03,28.36c-11.92-0.97-20.48-15.24-19.13-31.88  c1.36-16.63,12.12-29.34,24.04-28.37C507.8,364,516.36,378.27,515,394.91z"/>
  <path fill="none" :stroke="stroke1" stroke-width="14.9334" stroke-linecap="round" d="M306.65,628.78c0,0,43.28,163.2,225.4,14.88"/>
  <path fill="#FDFDFD" d="M388.05,387.18l-16.16,3.05l16.47,5.49C388.36,395.72,393.6,389.93,388.05,387.18z"/>
  <path :fill="fill2" d="M483.41,257.54c0,0,38.34-55.35,66.65-57.82c28.29-2.47,153.79,32.11,197.71-2.86  c43.92-34.99,60.21-115.12,12.7-104.4c-47.38,10.69-35.63,54.92-35.63,54.92s-16.04,6.63-30.38-3.87  c-14.34-10.49,16.86-99.83-32.29-103.85c-47.17-3.84-33.5,102.4-33.5,102.4s-28.05,14.99-47.72-0.4  c-10.35-8.09-9.78-75.88-34.54-60.73c-26.79,16.38-15.91,48.36-14.75,61.68c1.15,13.32-77.41,51.49-85.05,90.74  C438.98,272.6,483.41,257.54,483.41,257.54z" :stroke="stroke1" stroke-width="5px"/>
  <path :fill="fill2" d="M370.6,252.07c0,0-38.11-57.22-66.96-60.27c-28.85-3.05-157.59,29.9-201.77-6.62  C57.69,148.64,42.56,66.53,90.86,78.36c48.17,11.8,35.34,56.73,35.34,56.73s16.25,7.05,31.09-3.39  c14.83-10.44-15.33-102.23,34.91-105.4c48.23-3.04,32.29,105.16,32.29,105.16s28.35,15.83,48.73,0.48  c10.71-8.07,11.4-77.28,36.39-61.36c27.04,17.23,15.34,49.67,13.9,63.25c-1.43,13.57,78.06,54.01,85.12,94.21  C415.67,268.27,370.6,252.07,370.6,252.07z" :stroke="stroke1" stroke-width="5px"/>
  <path fill="#FDFDFD" d="M516.18,390.76l-16.16,3.05l16.46,5.48C516.49,399.29,521.73,393.51,516.18,390.76z"/>
  <g>
    <g>
      <path :fill="fill1" d="M534.3,269.27c0,0,114.1-34.48,130.29,2.34c2.11,46.92-110.16,77.1-110.16,77.1L534.3,269.27z"/>
    </g>
    <path :fill="fill2" d="M561.35,281.38c0,0,69.83-14.12,81.08-7.17c11.24,6.96-65.86,48.31-65.86,48.31L561.35,281.38z" :stroke="stroke1" stroke-width="5px"/>
  </g>
  <g>
    <g>
      <path :fill="fill1" d="M341.93,252.07c0,0-90.17-66.72-129.34-27.99C198.43,280.75,315.48,341,315.48,341L341.93,252.07z"/>
    </g>
    <path :fill="fill2" d="M314.13,259.97c0,0-68.06-33.13-79.78-27.57c-11.71,5.54,61.33,72.5,61.33,72.5L314.13,259.97z" :stroke="stroke1" stroke-width="5px"/>
  </g>
</svg>
</span>
</template>

<script>
import ThemeMixin from '../theme_mixin'

export default {
  name: 'reindeer',

  mixins: [ThemeMixin]
}
</script>
